import React from 'react';

function AboutUs() {
  return (
    <div>
      <h1>About Us</h1>
      <p>Here is some information about our company and services...</p>
    </div>
  );
}

export default AboutUs;
