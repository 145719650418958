import React, { useState, useEffect } from 'react';
import { Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material'; // Іконки для редагування та збереження
import { api } from '../../../Api';

const PersonalInfo = ({ data, t }) => {
    const [isEdit, setIsEdit] = useState(false);  
    const [dataLocal, setDataLocal] = useState({});  // Стейт для визначення, чи в режимі редагування

    const [formData, setFormData] = useState({
        name: data.name,
        email: data.email,
        phone: data.phone,
        address: data.address,
    });
    useEffect(() => {
        setDataLocal(data);
    }, [data]);
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value, // Оновлюємо значення в залежності від поля
        });
    };

    const handleSave = async () => {
        try {
            // Збереження даних на сервері (наприклад, зміна особистої інформації)
            const updatedData = await api.postData({data: formData, type:'user'}, '/user/save/' + data._id);
    
            // Якщо запит успішний, оновлюємо локальний стан та виходимо з режиму редагування
            if (updatedData._id) {
                setDataLocal(updatedData);
                console.log('Data saved:', updatedData);
                setIsEdit(false);  // Виходимо з режиму редагування
            } else {
                console.log('Error saving data');
            }
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };
    
    const handleCancel = () => {
        // Відновлюємо попередні значення даних
        setFormData({
            name: dataLocal.name,
            email: dataLocal.email,
            phone: dataLocal.phone,
            address: dataLocal.address,
        });
        setIsEdit(false);  // Виходимо з режиму редагування
    };

    return (
        <section className='personal-info'>
            <h2>
                {t('personalInfo')}
                <div onClick={() => setIsEdit(true)} className='edit'>
                    {t('edit')}
                </div>
            </h2>

            <p>
                <strong>{t('name')}:</strong> 
                {!isEdit ? (
                    dataLocal.name
                ) : (
                    <>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </>
                )}
            </p>
            <p>
                <strong>{t('email')}:</strong> 
                {!isEdit ? (
                    dataLocal.email
                ) : (
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                    />
                )}
            </p>
            <p>
                <strong>{t('phone')}:</strong> 
                {!isEdit ? (
                    dataLocal.phone
                ) : (
                    <input
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                    />
                )}
            </p>
            <p>
                <strong>{t('address')}:</strong> 
                {!isEdit ? (
                    dataLocal.address
                ) : (
                    <input
                        type="text"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                    />
                )}
            </p>
            <p>
                <strong>{t('balance')}:</strong> {dataLocal.balance}
            </p>

            {isEdit ? (
                <div className='edit-buttons'>
                    <button onClick={handleSave} className='save'>
                        <SaveIcon sx={{}} />
                        {t('save')}
                    </button>
                    <button onClick={handleCancel} className='cancel'>
                        {t('cancel')}
                    </button>
                </div>
            ) : null}
        </section>
    );
};

export default PersonalInfo;
