/* eslint-disable max-len */
import React, { useState } from 'react';
import { Formik, Field } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { apiActions } from '../../../bus/actions';
import * as Yup from 'yup';
import { TextField, Button, Typography, Container, Box } from '@mui/material';
import { api } from '../../../Api';
import { saveSession } from '../../../instruments';
import { useTranslation } from 'react-i18next';  // Імпортуємо useTranslation

// Схема для логіну
const LoginSchema = Yup.object().shape({
    username: Yup.string()
        .min(2, 'Username is too short')
        .max(30, 'Username is too long')
        .required('Username is required'),
    password: Yup.string()
        .min(2, 'Password is too short')
        .max(30, 'Password is too long')
        .required('Password is required'),
});

// Схема для реєстрації
const SignupSchema = Yup.object().shape({
    username: Yup.string()
        .min(2, 'Username is too short')
        .max(30, 'Username is too long')
        .required('Username is required'),
    password: Yup.string()
        .min(6, 'Password is too short')
        .max(30, 'Password is too long')
        .required('Password is required'),
    confirmPassword: Yup.string()
        .oneOf([ Yup.ref('password'), null ], 'Passwords must match')
        .required('Confirm Password is required'),
});

function AuthForm(props) {
    const { onClose } = props;
    const { t } = useTranslation();  // Використовуємо useTranslation
    const [ isSend, setIsSend ] = useState(false);
    const [ error, setError ] = useState(false);
    const [ isLogin, setIsLogin ] = useState(true); // Перемикання між формою логіну та реєстрації
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmit = async (values) => {
        setIsSend(true);
        const endpoint = isLogin ? '/login' : '/signup'; // Вибираємо ендпоінт залежно від режиму
        const data = await api.postData(values, endpoint);

        if (data && data.token) {
            saveSession(data);
            dispatch(apiActions.setIsLogged(true));
            dispatch(apiActions.setUserData(data));
            let url = '/admin/user/' + data._id;
            if(data.role === 'admin'){
                url = '/admin/users';
            }
            onClose();
            navigate(url);
        } else if (data && data.error) {
            setError(data.error);
            setIsSend(false);
        }
    };

    return (
        <Container
            maxWidth = 'sm'
            sx = {{ marginTop: '50px' }}>
            <Typography
                gutterBottom
                align = 'center'
                variant = 'h4'>
                {isLogin ? t('auth.login') : t('auth.signup')}  {/* Переклад заголовку */}
            </Typography>
            <Formik
                initialValues = {{
                    username:        '',
                    password:        '',
                    confirmPassword: '', // Поле підтвердження пароля для реєстрації
                }}
                validationSchema = { isLogin ? LoginSchema : SignupSchema }
                onSubmit = { handleSubmit }>
                {(formikProps) => (
                    <form onSubmit = { formikProps.handleSubmit }>
                        <Box mb = { 2 }>
                            <Field
                                fullWidth
                                required
                                as = { TextField }
                                autoComplete = 'username'
                                error = { formikProps.touched.username && Boolean(formikProps.errors.username) }
                                helperText = { formikProps.touched.username && formikProps.errors.username }
                                id = 'username'
                                label = {t('auth.username')}
                                name = 'username'
                                variant = 'outlined'
                            />
                        </Box>

                        <Box mb = { 2 }>
                            <Field
                                fullWidth
                                required
                                as = { TextField }
                                autoComplete = 'current-password'
                                error = { formikProps.touched.password && Boolean(formikProps.errors.password) }
                                helperText = { formikProps.touched.password && formikProps.errors.password }
                                id = 'password'
                                label = {t('auth.password')} 
                                name = 'password'
                                type = 'password'
                                variant = 'outlined'
                            />
                        </Box>

                        {!isLogin && (
                            <Box mb = { 2 }>
                                <Field
                                    fullWidth
                                    required
                                    as = { TextField }
                                    autoComplete = 'new-password'
                                    error = { formikProps.touched.confirmPassword && Boolean(formikProps.errors.confirmPassword) }
                                    helperText = { formikProps.touched.confirmPassword && formikProps.errors.confirmPassword }
                                    id = 'confirmPassword'
                                    label = {t('auth.confirmPassword')}
                                    name = 'confirmPassword'
                                    type = 'password'
                                    variant = 'outlined'
                                />
                            </Box>
                        )}

                        {error && (
                            <Box mb = { 2 }>
                                <Typography
                                    color = 'error'
                                    variant = 'body2'>
                                    {error}
                                </Typography>
                            </Box>
                        )}

                        <Box mt = { 2 }>
                            <Button
                                fullWidth
                                color = 'primary'
                                disabled = { isSend }
                                type = 'submit'
                                variant = 'contained'>
                                {isLogin ? t('auth.login') : t('auth.signup')}  {/* Переклад кнопки */}
                            </Button>
                        </Box>

                        <Box
                            mt = { 2 }
                            textAlign = 'center'>
                            <Button onClick = { () => setIsLogin(!isLogin) }>
                                {isLogin
                                    ? t("auth.noAccount")  // Переклад тексту для кнопки
                                    : t('auth.haveAccount')}  {/* Переклад тексту для кнопки */}
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Container>
    );
}

export default AuthForm;
