// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: {
                continue_tarif : 'continue_tarif',
                edit : 'edit',
                continue : 'continue',
                month: 'month',
                payment_summ: "Enter the top-up amount in USDT",
                contactUs: "Contact Us",
                info: "Here is some information about our company and services...",
                contactTelegram: "Contact via Telegram",
                telegramLink: "For any questions or support, feel free to reach out to us on Telegram:",
                qrCodeText: "Scan this QR code to contact us on Telegram:",
                botName: "@vpnMyServiceBot",
                dashboard: 'User Dashboard',
                personalInfo: 'Personal Information',
                name: 'Name',
                email: 'Email',
                phone: 'Phone',
                address: 'Address',
                balance: 'Balance',
                selectPaymentMethod: 'Select Payment Method',
                payWithCrypto: 'Pay with Crypto',
                selectPlan: 'Select a Plan',
                selectMonths: 'Select Months',
                numberOfMonths: 'Number of Months',
                confirmSelection: 'Confirm Selection',
                paymentInformation: 'Payment Information',
                amount: 'Amount',
                transactionId: 'Transaction ID',
                scanToPay: 'Scan to Pay',
                qrCodeAlt: 'QR Code for Payment',
                subscriptionConfirmation: 'Subscription Confirmation',
                successfullyActivated: 'You have successfully activated the',
                forMonths: 'for',
                months: 'months.',
                yourAccessCode: 'Your access code',
                saveAccessCode: 'Please save this access code for future reference.',
                insufficientFunds: 'Insufficient Funds',
                notEnoughBalance: 'You do not have enough balance to select this tariff.',
                tariffsHistory: 'Tariffs History',
                service: 'Service',
                startDate: 'Start Date',
                finishDate: 'Finish Date',
                vpnCode: 'VPN Code',
                status: 'Status',
                noServiceHistory: 'No service history available.',
                showMore: 'Show more...',
                transactionsHistory: 'Transactions History',
                date: 'Date',
                noTransactionHistory: 'No transaction history available.',
                confirmAlert: {
                    title: 'You selected the tariff',
                    message: 'Are you sure you want to select the tariff',
                    months: 'months?',
                    yes: 'Yes',
                    no: 'No',
                },
                auth: {
                    login: 'Login',
                    signup: 'Signup',
                    username: 'Username/Email',
                    password: 'Password',
                    confirmPassword: 'Confirm Password',
                    noAccount: "Don't have an account? Sign up",
                    haveAccount: 'Already have an account? Login',
                },
                banner: {
                    title: 'Protect Your Privacy with Our VPN',
                    description: 'Secure your online activity and browse the internet safely with our reliable VPN service.',
                    getStarted: 'Get started'
                },
                pricing: {
                    title: 'Our Pricing',
                    basicPlan: {
                        title: 'Basic Plan',
                        price: '$5/month',
                        traffic: '500 GB Traffic',
                        description1: 'For basic home users',
                        description2: 'Ideal for browsing, streaming, and social media.'
                    },
                    familyPlan: {
                        title: 'Family Plan',
                        price: '$10/month',
                        traffic: '2 TB Traffic',
                        description1: 'For families',
                        description2: 'Perfect for multiple devices and active users.'
                    },
                    premiumPlan: {
                        title: 'Premium Plan',
                        price: '$25/month',
                        traffic: 'Unlimited Traffic',
                        description1: 'For heavy internet users',
                        description2: 'Unlimited access to the internet without restrictions.'
                    },
                    dedicatedServer: {
                        title: 'Dedicated Server',
                        price: 'Custom Pricing',
                        traffic: 'Traffic on request',
                        description1: 'For dedicated needs',
                        tg_conttact: 'https://t.me/vpnMyServiceBot'
                    }
                },
                features: {
                    title: 'Why Choose Us?',
                    feature1: 'Reliable and stable connection',
                    feature2: 'Flexible plans for any user',
                    feature3: '24/7 customer support',
                    feature4: 'Custom solutions for unique needs',
                    feature5: 'Transparent terms without hidden fees'
                },
                footer: {
                    allRightsReserved: 'All rights reserved.',
                    aboutUs: 'About Us',
                    termsOfUse: 'Terms of Use',
                    privacyPolicy: 'Privacy Policy',
                    contact: 'Contact'
                },
                profile: 'Profile',
                logout: 'Logout',
                login: 'Login',
                register: 'Register',
                changeLang: 'Change Language',
            },
        },
        ua: {
            translation: {
                month: 'місяць',
                payment_summ: "Введіть сумму попвнення в USDT",
                contactUs: "Зв'яжіться з нами",
                info: "Ось деяка інформація про нашу компанію та послуги...",
                contactTelegram: "Зв'яжіться через Telegram",
                telegramLink: "Якщо у вас є питання або ви потребуєте підтримки, зв'яжіться з нами через Telegram:",
                qrCodeText: "Скануйте цей QR-код, щоб зв'язатися з нами в Telegram:",
                botName: "@vpnMyServiceBot",
                dashboard: 'Панель користувача',
                personalInfo: 'Особиста інформація',
                name: 'Ім\'я',
                email: 'Електронна пошта',
                phone: 'Телефон',
                address: 'Адреса',
                balance: 'Баланс',
                selectPaymentMethod: 'Оберіть спосіб оплати',
                payWithCrypto: 'Оплатити криптовалютою',
                selectPlan: 'Оберіть тариф',
                selectMonths: 'Оберіть кількісьть місяців',
                numberOfMonths: 'Кількість місяців',
                confirmSelection: 'Підтвердити вибір',
                paymentInformation: 'Інформація про платіж',
                amount: 'Сума',
                transactionId: 'Ідентифікатор транзакції',
                scanToPay: 'Скануйте для оплати',
                qrCodeAlt: 'QR код для оплати',
                subscriptionConfirmation: 'Підтвердження підписки',
                successfullyActivated: 'Ви успішно активували тариф',
                forMonths: 'на',
                months: 'місяців.',
                yourAccessCode: 'Ваш код доступу',
                saveAccessCode: 'Будь ласка, збережіть цей код для майбутнього використання.',
                insufficientFunds: 'Недостатньо коштів',
                notEnoughBalance: 'У вас недостатньо балансу для вибору цього тарифу.',
                tariffsHistory: 'Історія тарифів',
                service: 'Послуга',
                startDate: 'Дата початку',
                finishDate: 'Дата закінчення',
                vpnCode: 'VPN код',
                status: 'Статус',
                noServiceHistory: 'Історія послуг недоступна.',
                showMore: 'Показати більше...',
                transactionsHistory: 'Історія транзакцій',
                date: 'Дата',
                noTransactionHistory: 'Історія транзакцій недоступна.',
                confirmAlert: {
                    title: 'Ви вибрали тариф',
                    message: 'Ви впевнені, що бажаєте підключити тариф',
                    months: 'місяців?',
                    yes: 'Так',
                    no: 'Ні',
                },

                auth: {
                    login: 'Увійти',
                    signup: 'Реєстрація',
                    username: 'Логін/Електронна пошта',
                    password: 'Пароль',
                    confirmPassword: 'Підтвердження пароля',
                    noAccount: "Немає облікового запису? Зареєструйтесь",
                    haveAccount: 'Вже є обліковий запис? Увійти',
                },
                banner: {
                    title: 'Захистіть свою приватність за допомогою нашого VPN',
                    description: 'Захистіть свою онлайн-активність та безпечно переглядайте інтернет за допомогою нашого надійного VPN-сервісу.',
                    getStarted: 'Почати'
                },
                pricing: {
                    title: 'Наші ціни',
                    BasicPlan: {
                        title: 'Базовий план',
                        price: '5$/місяць',
                        traffic: '500 ГБ трафіку',
                        description1: 'Для базових домашніх користувачів',
                        description2: 'Ідеально для перегляду, стрімінгу та соціальних мереж.'
                    },
                    familyPlan: {
                        title: 'Сімейний план',
                        price: '10$/місяць',
                        traffic: '2 ТБ трафіку',
                        description1: 'Для сімей',
                        description2: 'Ідеально для декількох пристроїв та активних користувачів.'
                    },
                    premiumPlan: {
                        title: 'Преміум план',
                        price: '25$/місяць',
                        traffic: 'Необмежений трафік',
                        description1: 'Для активних інтернет-користувачів',
                        description2: 'Необмежений доступ до інтернету без обмежень.'
                    },
                    dedicatedServer: {
                        title: 'Виділений сервер',
                        price: 'Індивідуальне ціноутворення',
                        traffic: 'Трафік на вимогу',
                        description1: 'Для спеціальних потреб',
                        tg_conttact: "https://t.me/vpnMyServiceBot"
                    }
                },
                features: {
                    title: 'Чому обирають нас?',
                    feature1: 'Надійне та стабільне з\'єднання',
                    feature2: 'Гнучкі плани для будь-якого користувача',
                    feature3: 'Підтримка 24/7',
                    feature4: 'Індивідуальні рішення для унікальних потреб',
                    feature5: 'Прозорі умови без прихованих платежів'
                },
                footer: {
                    allRightsReserved: 'Всі права захищені.',
                    aboutUs: 'Про нас',
                    termsOfUse: 'Умови використання',
                    privacyPolicy: 'Політика конфіденційності',
                    contact: 'Контакти'
                },
                profile: 'Профіль',
                logout: 'Вихід',
                login: 'Увійти',
                register: 'Реєстрація',
                changeLang: 'Змінити мову',
            },
        },
        ru: {
            translation: {
                month: 'месяц',
                payment_summ: "Введите сумму пополнения в USDT",
                contactUs: "Свяжитесь с нами",
                info: "Вот некоторая информация о нашей компании и услугах...",
                contactTelegram: "Связаться через Telegram",
                telegramLink: "Если у вас есть вопросы или вам нужна поддержка, свяжитесь с нами через Telegram:",
                qrCodeText: "Отсканируйте этот QR-код, чтобы связаться с нами в Telegram:",
                botName: "@vpnMyServiceBot",
                dashboard: 'Панель пользователя',
                personalInfo: 'Личная информация',
                name: 'Имя',
                email: 'Электронная почта',
                phone: 'Телефон',
                address: 'Адрес',
                balance: 'Баланс',
                selectPaymentMethod: 'Выберите способ оплаты',
                payWithCrypto: 'Оплатить криптовалютой',
                selectPlan: 'Выберите тариф',
                selectMonths: 'Выберите количевство месяцов',
                numberOfMonths: 'Количество месяцев',
                confirmSelection: 'Подтвердить выбор',
                paymentInformation: 'Информация о платеже',
                amount: 'Сумма',
                transactionId: 'ID транзакции',
                scanToPay: 'Сканируйте для оплаты',
                qrCodeAlt: 'QR код для оплаты',
                subscriptionConfirmation: 'Подтверждение подписки',
                successfullyActivated: 'Вы успешно активировали тариф',
                forMonths: 'на',
                months: 'месяцев.',
                yourAccessCode: 'Ваш код доступа',
                saveAccessCode: 'Пожалуйста, сохраните этот код для дальнейшего использования.',
                insufficientFunds: 'Недостаточно средств',
                notEnoughBalance: 'У вас недостаточно баланса для выбора этого тарифа.',
                tariffsHistory: 'История тарифов',
                service: 'Услуга',
                startDate: 'Дата начала',
                finishDate: 'Дата окончания',
                vpnCode: 'VPN код',
                status: 'Статус',
                noServiceHistory: 'История услуг недоступна.',
                showMore: 'Показать больше...',
                transactionsHistory: 'История транзакций',
                date: 'Дата',
                noTransactionHistory: 'История транзакций недоступна.',
                confirmAlert: {
                    title: 'Вы выбрали тариф',
                    message: 'Вы уверены, что хотите подключить тариф',
                    months: 'месяцев?',
                    yes: 'Да',
                    no: 'Нет',
                },
                auth: {
                    login: 'Войти',
                    signup: 'Регистрация',
                    username: 'Логин/Электронная почта',
                    password: 'Пароль',
                    confirmPassword: 'Подтверждение пароля',
                    noAccount: "Нет аккаунта? Зарегистрируйтесь",
                    haveAccount: 'Уже есть аккаунт? Войти',
                },
                banner: {
                    title: 'Защитите свою конфиденциальность с нашим VPN',
                    description: 'Защитите свою онлайн-активность и безопасно просматривайте интернет с помощью нашего надежного VPN-сервиса.',
                    getStarted: 'Начать'
                },
                pricing: {
                    title: 'Наши цены',
                    basicPlan: {
                        title: 'Базовый план',
                        price: '5$/месяц',
                        traffic: '500 ГБ трафика',
                        description1: 'Для базовых домашних пользователей',
                        description2: 'Идеально для просмотра, стриминга и социальных сетей.'
                    },
                    familyPlan: {
                        title: 'Семейный план',
                        price: '10$/месяц',
                        traffic: '2 ТБ трафика',
                        description1: 'Для семей',
                        description2: 'Идеально для нескольких устройств и активных пользователей.'
                    },
                    premiumPlan: {
                        title: 'Премиум план',
                        price: '25$/месяц',
                        traffic: 'Неограниченный трафик',
                        description1: 'Для активных интернет-пользователей', 
                        description2: 'Неограниченный доступ к интернету без ограничений.'
                    },
                    dedicatedServer: {
                        title: 'Выделенный сервер',
                        price: 'Индивидуальное ценообразование',
                        traffic: 'Трафик по запросу',
                        description1: 'Для специальных нужд',
                        tg_conttact: "https://t.me/vpnMyServiceBot"
                    }
                },
                features: {
                    title: 'Почему выбирают нас?',
                    feature1: 'Надежное и стабильное соединение',
                    feature2: 'Гибкие планы для любого пользователя',
                    feature3: 'Поддержка 24/7',
                    feature4: 'Индивидуальные решения для уникальных потребностей',
                    feature5: 'Прозрачные условия без скрытых сборов'
                },
                footer: {
                    allRightsReserved: 'Все права защищены.',
                    aboutUs: 'О нас',
                    termsOfUse: 'Условия использования',
                    privacyPolicy: 'Политика конфиденциальности',
                    contact: 'Контакты'
                },
                profile: 'Профиль',
                logout: 'Выход',
                login: 'Войти',
                register: 'Регистрация',
                changeLang: 'Изменить язык',
            },
        },
    },
    lng: 'en', // За замовчуванням
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
