import React from 'react';
import { useTranslation } from 'react-i18next';
import './Contact.css';  // імпортуємо CSS файл

function Contact() {
  const { t } = useTranslation();

  return (
    <div className="contact-container">
      <div className="contact-header">
        <h1>{t('contactUs')}</h1>
      </div>
      <p className="contact-info">{t('info')}</p>

      <div className="contact-telegram">
        <h2>{t('contactTelegram')}</h2>
        <p>{t('telegramLink')}</p>
        <a 
          href="https://t.me/vpnMyServiceBot" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="contact-link"
        >
          {t('botName')} 
        </a>
      </div>

      <div className="contact-qr">
        <h2>{t('qrCodeText')}</h2>
        <p>{t('qrCodeText')}</p>
        <div className="qr-code">
          <img 
            src="/qr.png" 
            alt="QR Code to VPNMyServiceBot" 
            className="qr-image" 
          />
        </div>
      </div>
    </div>
  );
}

export default Contact;
