// eslint-disable-line
export const book = Object.freeze({
    home:  '/',
    login: '/login',
    admin: {
        userSingle: '/admin/user/:u_id',
        users:        '/admin/users',

    },
});
