import React, { useState, useEffect } from 'react';
import { api } from '../../../Api';
import './Single.css';
import { confirmAlert } from 'react-confirm-alert';
import '../../../theme/styles/confirmAllert.css';
import { useTranslation } from 'react-i18next'; // Імпортуємо useTranslation
import PersonalInfo from './personal';
function Single() {
    const { t } = useTranslation();  // Використовуємо useTranslation для перекладів
    const urlArr = window.location.pathname.split('/');
    const id = urlArr[urlArr.length - 1];
    const [data, setData] = useState(null);
    const [paymentData, setPaymentData] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [tariffData, setTariffData] = useState(null);
    const [isModalTariffOpen, setIsModalTariffOpen] = useState(false);
    const [isInsufficientFunds, setIsInsufficientFunds] = useState(false);
    const [isMonthsModalOpen, setIsMonthsModalOpen] = useState(false); // стан для модального вікна вибору місяців
    const [visibleItems, setVisibleItems] = useState(2);
    const [visibleTransactions, setVisibleTransactions] = useState(2);
    const [balance, setBalance] = useState('');
    const [selectedMonths, setSelectedMonths] = useState(1);
    const [selectedTariff, setSelectedTariff] = useState(null);
    const [hovered, setHovered] = useState(null); // Для відстеження, який тариф активний при наведенні

    const tariffs = {
        Basic: { price: 5, title: 'basicPlan' },
        Family: { price: 10, title: 'familyPlan' },
        Premium: { price: 25, title: 'premiumPlan' },
        dedicatedServer: {
            title: 'dedicatedServer',
            link: t('pricing.dedicatedServer.tg_conttact'),
            text: t('contactUs')
        },
    };

    const continue_tariff = (tariff) => {
        confirmAlert({
            title: t('confirmAlert.title'),
            message: `${t('confirmAlert.message')} ${selectedMonths} ${t('confirmAlert.months')}`,
            overlayClassName: 'confirmAlertOverlay',
            buttons: [
                {
                    label: t('confirmAlert.yes'),
                    onClick: async () => {
                        const writeOffBalance = await api.postData(
                            { userId: id, tariff: tariff },
                            '/payment/writeOffBalance_continue',
                        );
                        if (writeOffBalance) {
                            setTariffData(writeOffBalance);
                            setIsModalTariffOpen(true);
                        }
                    },
                    className: 'confirm',
                },
                {
                    label: t('confirmAlert.no'),
                    className: 'cancel',
                },
            ],
        });
    };

    const handleConfirmAlert = (tariff, amount) => {
        confirmAlert({
            title: t('confirmAlert.title'),
            message: `${t('confirmAlert.message')} ${selectedMonths} ${t('confirmAlert.months')}`,
            overlayClassName: 'confirmAlertOverlay',
            buttons: [
                {
                    label: t('confirmAlert.yes'),
                    onClick: async () => {
                        const writeOffBalance = await api.postData(
                            { userId: id, plan: tariff, amount: amount, mounth: selectedMonths },
                            '/payment/writeOffBalance',
                        );
                        if (writeOffBalance) {
                            setTariffData(writeOffBalance);
                            setIsModalTariffOpen(true);
                        }
                    },
                    className: 'confirm',
                },
                {
                    label: t('confirmAlert.no'),
                    className: 'cancel',
                },
            ],
        });
    };

    const getData = async () => {
        const data = await api.postData({ type: 'user' }, '/user/' + id);
        if (data.data) {
            setData(data.data);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const handleInputChange = (event) => {
        setBalance(event.target.value);
        handlePaymentSelection();
    };

    const handlePaymentSelection = async (paymentType) => {
        if (paymentType === 'up_balance' && balance > 0) {
            try {
                const transactionData = await api.postData(
                    { userId: id, amount: balance },
                    '/payment/create-crypto-transaction',
                );
                if (transactionData) {
                    setPaymentData(transactionData);
                    setIsModalOpen(true);
                }
            } catch (error) {
                console.error("Error creating crypto transaction:", error);
            }
        }
    };

    const handleTariff = (tariffText, tariff) => {
        console.log(tariff)
        if (tariff.link) {
            window.open(tariff.link, '_blank');
            return;
        }
        setSelectedTariff(tariffText);
        setIsMonthsModalOpen(true); // відкриваємо модальне вікно для вибору місяців
    };

    const confirmTariffSelection = () => {
        if (selectedTariff) {
            const amount = tariffs[selectedTariff].price * selectedMonths;
            if (data.balance >= amount) {
                handleConfirmAlert(selectedTariff, amount);
            } else {
                setIsInsufficientFunds(true);
            }
            setIsMonthsModalOpen(false); // закриваємо модальне вікно після вибору
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setIsInsufficientFunds(false);
        setIsMonthsModalOpen(false);
        setIsModalTariffOpen(false);
        setTariffData(null);
    };

    const tariffJSX = Object.keys(tariffs).map((el) => {
        const tariff = tariffs[el];

        return (
            <div key={el}
                className={`pricing-card ${hovered === el ? 'hovered' : ''}`}
                onClick={() => handleTariff(el, tariff)}
                onMouseEnter={() => setHovered(el)}
                onMouseLeave={() => setHovered(null)}
            >

                {hovered === el && tariff ? ( // Якщо тариф наведенний і має description
                    <p className="description">{t('pricing.' + tariff.title + '.description1')}</p>
                ) : (
                    <>
                        <h3>{t('pricing.' + tariffs[el].title + '.title')}</h3>

                        {tariffs[el].text && tariffs[el].link ? (
                            tariffs[el].text
                        ) : null}
                        {tariffs[el].price ? (
                            <p>{tariffs[el].price}$/{t('month')}</p>
                        ) : null}
                    </>
                )}
            </div>
        );
    });



    const showMoreItems = () => {
        setVisibleItems((prevCount) => prevCount + 2);
    };

    const showMoreTransactions = () => {
        setVisibleTransactions((prevCount) => prevCount + 2);
    };

    return data && Object.keys(data).length > 0 ? (
        <div className='profile-container'>
            <h1>{t('dashboard')}</h1>
            <PersonalInfo
                data={data}
                t={t}
            />

            <section className='payment-selection'>
                <h2>{t('selectPaymentMethod')}</h2>
                <input className='top_up_balance' placeholder={t('payment_summ')} value={balance} onChange={handleInputChange}></input>
                <div className='payment-buttons'>
                    <button onClick={() => handlePaymentSelection('up_balance')}>{t('payWithCrypto')}</button>
                </div>
            </section>
            <h2 style={{ textAlign: 'center' }}>{t('selectPlan')}</h2>

            <section className='pricing-options'>
                <div className='pricing-cards'>{tariffJSX}</div>
            </section>

            {isMonthsModalOpen && (
                <div className='modal'>
                    <div className='modal-content'>
                        <span className='close' onClick={closeModal}>&times;</span>
                        <h2>{t('selectMonths')}</h2>
                        <label>
                            {t('numberOfMonths')}:
                            <input
                                type='number'
                                value={selectedMonths}
                                onChange={(e) => setSelectedMonths(Number(e.target.value))}
                                min='1'
                                style={{ width: '100%', padding: '10px', marginTop: '10px', marginBottom: '20px' }}
                            />
                        </label>
                        <button onClick={confirmTariffSelection} className='confirm-btn'>
                            {t('confirmSelection')}
                        </button>
                    </div>
                </div>
            )}

            {isModalOpen && paymentData && (
                <div className='modal'>
                    <div className='modal-content'>
                        <span className='close' onClick={closeModal}>&times;</span>
                        <h2>{t('paymentInformation')}</h2>
                        <p><strong>{t('amount')}:</strong> {paymentData.amount}</p>
                        <p><strong>{t('transactionId')}:</strong> {paymentData.txn_id}</p>
                        <p><strong>{t('address')}:</strong> {paymentData.address}</p>
                        {paymentData.qrcode_url && (
                            <div className='qr-code'>
                                <h3>{t('scanToPay')}</h3>
                                <img alt={t('qrCodeAlt')} src={paymentData.qrcode_url} />
                            </div>
                        )}
                    </div>
                </div>
            )}

            {isModalTariffOpen && tariffData && (
                <div className='modal'>
                    <div className='modal-content'>
                        <span className='close' onClick={closeModal}>&times;</span>

                        {tariffData.continue ? (
                            <>
                                <h2>{t('subscriptionConfirmation')}</h2>
                                <p>
                                    {t('successfullyActivated')} <strong>{selectedTariff}</strong> {t('forMonths')} <strong>{selectedMonths}</strong> {t('months')}.
                                </p>
                            </>
                        ) : null}
                        {tariffData.accessUrl ? (
                            <>
                                <h2>{t('subscriptionConfirmation')}</h2>
                                <p>
                                    {t('successfullyActivated')} <strong>{selectedTariff}</strong> {t('forMonths')} <strong>{selectedMonths}</strong> {t('months')}.
                                </p>
                                <p>{t('yourAccessCode')}:</p>
                                <div className="access-code">
                                    <strong>{tariffData.accessUrl}</strong>
                                </div>
                                <p>{t('saveAccessCode')}</p>
                            </>
                        ) : null}
                        {!tariffData.continue && !tariffData.accessUrl ?  (
                            <>
                                <h2>{t('error')}</h2>
                            </>
                        ) : null}

                    </div>
                </div>
            )}

            {isInsufficientFunds && (
                <div className='modal'>
                    <div className='modal-content'>
                        <span className='close' onClick={closeModal}>&times;</span>
                        <h2>{t('insufficientFunds')}</h2>
                        <p>{t('notEnoughBalance')}</p>
                    </div>
                </div>
            )}

            <section className="service-history">
                <h2>{t('tariffsHistory')}</h2>
                {data && data.tariffs && data.tariffs.length > 0 ? (
                    <ul style={{ marginTop: '10px' }}>
                        {data.tariffs.slice(0, visibleItems).map((item, index) => (
                            <li key={index} style={{ color: item.status === 'active' ? 'green' : item.status === 'close' ? 'orange' : 'red' }}>
                                <strong>{t('service')}:</strong> {item.tariff} <br />
                                <strong>{t('startDate')}:</strong> {new Date(item.start_date).toLocaleDateString()} <br />
                                <strong>{t('finishDate')}:</strong> {new Date(item.finish_date).toLocaleDateString()} <br />
                                <strong>{t('vpnCode')}:</strong> <span className='fs10'>{item?.vpn?.accessUrl}</span> <br />
                                <strong>{t('amount')}:</strong> ${item.sum} <br />
                                <strong>{t('status')}:</strong> {item.status} <br />
                                <div onClick={() => continue_tariff(item)} className='continue_tariff'>{t('continue')}</div>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>{t('noServiceHistory')}</p>
                )}
                {data && data.tariffs && visibleItems < data.tariffs.length && (
                    <div style={{ textAlign: 'center' }}>
                        <button className='show_more_btn' onClick={showMoreItems}>
                            {t('showMore')}
                        </button>
                    </div>
                )}
            </section>

            <section className="transaction-history">
                <h2>{t('transactionsHistory')}</h2>
                {data && data.transactions && data.transactions.length > 0 ? (
                    <ul style={{ marginTop: '10px' }}>
                        {data.transactions.slice(0, visibleTransactions).map((item, index) => (
                            <li key={index} >
                                <strong>{t('date')}:</strong> {new Date(item.createdAt).toLocaleDateString()} <br />
                                <strong>{t('amount')}:</strong> ${item.amount}<br />
                                <strong >{t('status')}:</strong> <span style={{ fontSize: '18px', color: item.status === 'success' ? 'green' : item.status === 'pending' ? 'orange' : 'red' }}>{item.status} </span><br />
                                <strong>{t('transactionId')}:</strong> {item.txn_id}
                                {item.status === 'pending' ? (
                                    <>
                                        <p><strong>{t('address')}:</strong> {item.address}</p>
                                        <div className='qr-code'>
                                            <img alt={t('qrCodeAlt')} src={item.qrcode_url} />
                                        </div>
                                    </>
                                ) : null}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>{t('noTransactionHistory')}</p>
                )}
                {data && data.transactions && visibleTransactions < data.transactions.length && (
                    <div style={{ textAlign: 'center' }}>
                        <button className='show_more_btn' onClick={showMoreTransactions}>
                            {t('showMore')}
                        </button>
                    </div>
                )}
            </section>
        </div>
    ) : null;
}

export default Single;
