import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Menu, MenuItem, IconButton, Typography, Dialog, DialogContent, FormControl } from '@mui/material';
import { styled } from '@mui/system';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { removeSession } from '../../instruments';
import { apiActions } from '../../bus/actions';
import AuthForm from '../Account/Login/login';
import { useTranslation } from 'react-i18next';  // Імпортуємо i18n

const StyledAppBar = styled(AppBar)`
  background: linear-gradient(90deg, #627173, #45475b);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
`;

const StyledButton = styled(Button)`
  color: white;
  font-size: 16px;
  margin: 0 10px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const LogoTypography = styled(Typography)`
  font-size: 24px;
  font-weight: bold;
  color: white;
  flex-grow: 1;
  cursor: pointer;
`;

function Header() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();  // Використовуємо useTranslation

    const selectState = (state) => ({
        userData: state.swapi.userData,
        lang: state.swapi.lang,  // Отримуємо мову з глобального стейту
    });

    const state = useSelector(selectState);
    const { userData, lang } = state;
    const [selectedLang, setSelectedLang] = useState(lang || 'en');  // Стан для вибору мови

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleDialogOpen = (content) => {
        setDialogContent(content);
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const logout = () => {
        handleMenuClose();
        removeSession();
        dispatch(apiActions.setIsLogged(false));
        dispatch(apiActions.setUserData(null));
        navigate('/');
    };

    const changeLanguage = (event) => {
        const lang = event.target.value;
        setSelectedLang(lang);  // Оновлюємо локальний стан для мови
        dispatch(apiActions.setLanguage(lang));  // Оновлюємо Redux
        i18n.changeLanguage(lang);  // Оновлюємо i18next
    };

    useEffect(() => {
        dispatch(apiActions.setLanguage(selectedLang));  // Зберігаємо вибрану мову в глобальному стейті
        i18n.changeLanguage(selectedLang);  // Оновлюємо i18next для зміни мови
    }, [selectedLang, dispatch, i18n]);
    useEffect(() => {
        setSelectedLang(lang)
    }, [lang]);
    return (
        <>
            <StyledAppBar position='static'>
                <Toolbar>
                    <LogoTypography
                        component={Link}
                        to='/'
                        variant='h6'>
                        VPN Service
                    </LogoTypography>
                    {userData ? (
                        <>
                            <IconButton
                                color='inherit'
                                onClick={handleMenuOpen}>
                                <AccountCircle fontSize='large' />
                            </IconButton>
                            <Menu
                                PaperProps={{
                                    style: {
                                        backgroundColor: '#333',
                                        color: 'white',
                                    },
                                }}
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}>
                                <MenuItem
                                    component={Link}
                                    style={{ color: 'white' }}
                                    to={'/admin/user/' + userData._id}
                                    onClick={handleMenuClose}>
                                    {t('profile')}
                                </MenuItem>
                                <MenuItem
                                    style={{ color: 'white' }}
                                    onClick={logout}>
                                    {t('logout')}
                                </MenuItem>
                            </Menu>
                        </>
                    ) : (
                        <>
                            <StyledButton onClick={() => handleDialogOpen('login')}>{t('login')}</StyledButton>
                            <StyledButton onClick={() => handleDialogOpen('register')}>{t('register')}</StyledButton>
                        </>
                    )}
                    <FormControl>
                        <select
                            id="lang-select"
                            value={selectedLang}
                            onChange={changeLanguage}
                            style={{
                                color: 'white',
                                backgroundColor: 'transparent',
                                border: '1px solid white',
                                padding: '5px 10px',
                                fontSize: '16px',
                                outline: 'none',
                                borderRadius: '4px',
                            }}
                        >
                            <option value="en">English</option>
                            <option value="ua">Українська</option>
                            <option value="ru">Русский</option>
                        </select>

                    </FormControl>
                </Toolbar>
            </StyledAppBar>

            <Dialog
                fullWidth
                PaperProps={{
                    style: { backgroundColor: '#f5f5f5' },
                }}
                maxWidth='sm'
                open={openDialog}
                onClose={handleDialogClose}>
                <DialogContent>
                    {dialogContent === 'login' && <AuthForm onClose={handleDialogClose} />}
                    {dialogContent === 'register' && <AuthForm onClose={handleDialogClose} />}
                </DialogContent>
            </Dialog>
        </>
    );
}

export default Header;
