import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';  // Імпортуємо Link з react-router-dom
import { useTranslation } from 'react-i18next';  // Імпортуємо useTranslation
import './home.css';

const selectState = (state) => ({
    userData: state.swapi.userData,
});

function Home() {
    const { t } = useTranslation();  // Використовуємо useTranslation
    const state = useSelector(selectState);
    const { userData } = state;

    return (
        <>
            <section className='banner'>
                <div className='container'>
                    <h1>{t('banner.title')}</h1>  
                    <p>{t('banner.description')}</p> 
                    <Link to={userData?._id ? '/admin/user/' + userData?._id : '/login'}>
                        <button>{t('banner.getStarted')}</button> 
                    </Link>
                </div>
            </section>

            <section className='pricing home'>
                <div className='container'>
                    <h2>{t('pricing.title')}</h2> 
                    <div className='pricing-options'>
                        <div className='pricing-card'>
                            <h3>{t('pricing.basicPlan.title')}</h3>
                            <p>{t('pricing.basicPlan.price')}</p>
                            <p>{t('pricing.basicPlan.traffic')}</p>
                            <p>{t('pricing.basicPlan.description1')}</p>
                            <p>{t('pricing.basicPlan.description2')}</p>
                        </div>
                        <div className='pricing-card'>
                            <h3>{t('pricing.familyPlan.title')}</h3>
                            <p>{t('pricing.familyPlan.price')}</p>
                            <p>{t('pricing.familyPlan.traffic')}</p>
                            <p>{t('pricing.familyPlan.description1')}</p>
                            <p>{t('pricing.familyPlan.description2')}</p>
                        </div>
                        <div className='pricing-card'>
                            <h3>{t('pricing.premiumPlan.title')}</h3>
                            <p>{t('pricing.premiumPlan.price')}</p>
                            <p>{t('pricing.premiumPlan.traffic')}</p>
                            <p>{t('pricing.premiumPlan.description1')}</p>
                            <p>{t('pricing.premiumPlan.description2')}</p>
                        </div>
                        <div className='pricing-card'>
                            <h3>{t('pricing.dedicatedServer.title')}</h3>
                            <p>{t('pricing.dedicatedServer.price')}</p>
                            <p>{t('pricing.dedicatedServer.traffic')}</p>
                            <p>{t('pricing.dedicatedServer.description1')}</p>
                            <a  style= {{color:'blue', fontSize:'14px'}}  target='_blank' rel="noreferrer" href={t('pricing.dedicatedServer.tg_conttact')}>{t('pricing.dedicatedServer.tg_conttact')}</a>
                        </div>
                    </div>
                </div>
            </section>

            <section className='features'>
                <div className='container'>
                    <h2>{t('features.title')}</h2>  {/* Переклад заголовку розділу "Особливості" */}
                    <ul>
                        <li>{t('features.feature1')}</li>
                        <li>{t('features.feature2')}</li>
                        <li>{t('features.feature3')}</li>
                        <li>{t('features.feature4')}</li>
                        <li>{t('features.feature5')}</li>
                    </ul>
                </div>
            </section>

            <footer className='footer'>
                <div className='container'>
                    <p>&copy; 2024 VPN Service. {t('footer.allRightsReserved')}</p>
                    <ul className="footer-links">
                        <li><Link to="/about">{t('footer.aboutUs')}</Link></li>
                        <li><Link to="/terms">{t('footer.termsOfUse')}</Link></li>
                        <li><Link to="/privacy">{t('footer.privacyPolicy')}</Link></li>
                        <li><Link to="/contact">{t('footer.contact')}</Link></li>
                    </ul>
                </div>
            </footer>
        </>
    );
}

export default Home;
