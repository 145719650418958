import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { book } from '../../../instruments';
import Single from '../Users/single';


export const UserRouter = (props) => {
    return (
        <Routes>
            <Route
                exact
                element = {
                    <Single
                        { ...props }
                    />
                }
                path = { book.admin.userSingle }
            />
        </Routes>
    );
};
